export default {
  excelEditorLabel: {
    footerLeft: (top, bottom, total) => `레코드 ${top} ~ ${bottom} 개 / 총 ${total} 개`,
    first: '처음',
    previous: '이전',
    next: '다음',
    last: '끝',
    footerRight: {
      selected: '선택:',
      filtered: '필터:',
      loaded: '로드:'
    },
    processing: '진행중',
    tableSetting: 'Table Setting',
    exportExcel: '엑셀 추출',
    importExcel: '엑셀 업로드',
    back: '뒤로',
    reset: '리셋',
    sortingAndFiltering: '정렬/필터',
    sortAscending: '내림차순',
    sortDescending: '오름차순',
    near: '≒ 유사',
    exactMatch: '= 일치',
    notMatch: '≠ 불일치',
    greaterThan: '&gt; 초과',
    greaterThanOrEqualTo: '≥ 이상',
    lessThan: '&lt; 미만',
    lessThanOrEqualTo: '≤ 이하',
    regularExpression: '~ 정규식',
    customFilter: '사용자필터',
    listFirstNValuesOnly: n => `List first ${n} values only`,
    apply: '적용',
    noRecordIsRead: '레코드없음',
    readonlyColumnDetected: '읽기전용 컬럼',
    columnHasValidationError: (name, err) => `컬럼 ${name}값 검증 오류: ${err}`,
    noMatchedColumnName: '일치하는컬럼 없음',
    invalidInputValue: '입력값 오류',
    missingKeyColumn: '키컬럼 없음',
    noRecordIndicator: '레코드 없음'
  },
  // 배차 유형
  drvCode: {
    map: {
      '': '미입력',
      D: '딜리버리',
      P: '픽업'
    },
    variant: {
      D: 'outline-info',
      P: 'outline-warning'
    },
    options: [
      {value:'',  label: '배차유형 선택'},
      {value:'D', label: '딜리버리'},
      {value:'P', label: '픽업'},
    ],
    opts: [
      {value:'',  text: '배차유형 선택'},
      {value:'D', text: '딜리버리'},
      {value:'P', text: '픽업'},
    ],
    opt: [
      {value:'D', text: '딜리버리'},
      {value:'P', text: '픽 업'},
    ]

  },
  // 차량 유형
  carCls: {
    map: {
      '': '미입력',
      '10': '소형',
      '20': '중형',
      '30': '대형'
    },
    options: [
      {value:'',   label: '차량유형 선택'},
      {value:'10',  label: '소형'},
      {value:'20',  label: '중형'},
      {value:'30',  label: '대형'}
    ],
    opts: [
      {value:'',    text: '차량유형 선택'},
      {value:'10',  text: '소형'},
      {value:'20',  text: '중형'},
      {value:'30',  text: '대형'}
    ],
    opt: [
      {value:'10',  text: '소형'},
      {value:'20',  text: '중형'},
      {value:'30',  text: '대형'}
    ]
  },
  payCode: {
    // 결제방식(앞1자리=콜마너코드) 21:후불-법인청구, 22:후불-계좌입금, 03:현금, 74:카드, 85:마일리지결제, 96:복합결제
    map: {
      '21': '후불-법인청구',
      '22': '후불-계좌입금',
      '03': '현금',
      '74': '카드',
      '85': '마일리지',
      '96': '복합결제'
    },
    opts: [ // BSelect 사용
      {value: null, text: '결제방식 선택'},
      {value: '21', text: '후불-법인청구'},
      {value: '22', text: '후불-계좌입금'},
      {value: '03', text: '현금'},
      {value: '74', text: '카드'},
      {value: '85', text: '마일리지'},
      {value: '96', text: '복합결제'},
    ],
    opt: [
      {value: '21', text: '후불-법인청구'},
      {value: '22', text: '후불-계좌입금'},
      {value: '03', text: '현금'},
      {value: '74', text: '카드'},
      {value: '85', text: '마일리지'},
      {value: '96', text: '복합결제'},
    ]
  },
  roundTrip: {
    map: {
      true: '왕복',
      false: '편도'
    },
    opts: [
      {value: null, text: '선택'},
      {value: false, text: '편도'},
      {value: true, text: '왕복'}
    ],
    opt: [
      {value: false, text: '편도'},
      {value: true, text: '왕복'}
    ]
  },
  sts: {
    map: {
      '': '미정',
      '00':'완료',
      '10':'탁송예약',
      '11':'접수확인',
      '12':'배차요청',
      '20':'기사배차',
      '21':'재배차',
      '29':'배차취소',
      '30':'탁송시작',
      '31':'경유1도착',
      '32':'경유2도착',
      '33':'경유3도착',
      '34':'경유4도착',
      '35':'경유5도착',
      '99':'예약취소',
    },
    options: [
      {value: '',   label:'미정'},
      {value: '00', label:'완료'},
      {value: '10', label:'탁송예약'},
      {value: '11', label:'접수확인'},
      {value: '12', label:'배차요청'},
      {value: '20', label:'기사배차'},
      {value: '21', label:'재배차'},
      {value: '29', label:'배차불가'},
      {value: '30', label:'탁송시작'},
      {value: '31', label:'경유1도착'},
      {value: '32', label:'경유2도착'},
      {value: '33', label:'경유3도착'},
      {value: '34', label:'경유4도착'},
      {value: '35', label:'경유5도착'},
      {value: '99', label:'예약취소'}

    ],
    opts: [
      {value: '',   text:'미정'},
      {value: '00', text:'완료'},
      {value: '10', text:'탁송예약'},
      {value: '11', text:'접수확인'},
      {value: '12', text:'배차요청'},
      {value: '20', text:'기사배차'},
      {value: '21', text:'재배차'},
      {value: '29', text:'배차불가'},
      {value: '30', text:'탁송시작'},
      {value: '31', text:'경유1도착'},
      {value: '32', text:'경유2도착'},
      {value: '33', text:'경유3도착'},
      {value: '34', text:'경유4도착'},
      {value: '35', text:'경유5도착'},
      {value: '99', text:'예약취소'}
    ],
    opt: [
      {value: '00', text:'완료'},
      {value: '10', text:'탁송예약'},
      {value: '11', text:'접수확인'},
      {value: '12', text:'배차요청'},
      {value: '20', text:'기사배차'},
      {value: '21', text:'재배차'},
      {value: '29', text:'배차불가'},
      {value: '30', text:'탁송시작'},
      {value: '31', text:'경유1도착'},
      {value: '32', text:'경유2도착'},
      {value: '33', text:'경유3도착'},
      {value: '34', text:'경유4도착'},
      {value: '35', text:'경유5도착'},
      {value: '99', text:'예약취소'}
    ]
  },

  pathMap: {},

  unitVolOpts : [
    { value: null, text: '부피 단위'},
    { value: 'l', text: '리터(L)'},
    { value: 'g', text: '갤런(Gl)'},
    // { value: 'd', text: '드럼(D)'},
    // { value: 'b', text: '배럴(bbl)'},
  ],
  unitLenOpts: [
    { value: null, text: '길이 단위'},
    { value: 'mm', text: '밀리미터(mm)'},
    { value: 'i', text: '인치(In)'},
  ],
  unitTmpOpts: [
    { value: null, text: '온도 단위'},
    { value: 'c', text: '섭씨(°C)'},
    { value: 'f', text: '화씨(°F)'},
  ]
}
