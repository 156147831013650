/**
 * Database Schema for vue components
 */
export const _driveRequest = {
  drvDts: '',
  drvDt: null,
  drvYmd: '', // YYYY-MM-DD
  drvHms: '', // HH:mm:ss
  custCode: '',
  centerCode: '',
  wgCode: '', // workgroup code = supervisor id
  counselor: '',
  drvKey: '',
  drvCode: 'D', // 배차유형 D:Drive(센터->고객), P:Pickup(고객->센터)
  spOrder      : null,
  spKey       : '',
  spDts        : '',
  farCls: 'G',  // 운행 지역 등급 (General:일반,Middle:준격오,Special:특수지역)
  carCls: '10', // 차량유형 10:소형: 20:중형, 30:대형
  carKind: '',
  carOwner: '',
  plateNo: '',
  ownerHpNo: '',
  isVip: false,
  reqId: '',
  reqName: '',
  reqHpNo: '',
  acqName: '',
  acqHpNo: '',
  acqId: '',
  reqMessage: '',
  acqMessage: '',
  stAddr: '',
  stAddrDtl: '',
  stRegionAddr: {
    rga: '', // 구주소 전체
    rg1: '', // 구주소 시도
    rg2: '', // 구주소 구군
    rg3: '', // 구주소 동면
    mNo: '', // main 번지수
    sNo: '', // 서브 번지수
  },
  edRegionAddr: {
    rga: '', // 구주소 전체
    rg1: '', // 구주소 시도
    rg2: '', // 구주소 구군
    rg3: '', // 구주소 동면
    mNo: '', // main 번지수
    sNo: '', // 서브 번지수
  },
  stXy: '',
  stDt: '',

  edAddr: '',
  edAddrDtl: '',
  edXy: '',
  edDt: '',

  routes: [],
  isRoundTrip: false,
  driverName: '',
  driverHpNo: '',
  driverNo: '',
  driverXy: '',
  dstKm: 0,
  drvKm: 0,
  waitTm: 0, // 대기시간
  drvTm: 0, // 총운행시간
  toDriverMsg: '',
  toCenterMsg: '',
  toOfficeMsg: '',
  remark: '',
  sts: '10', // 진행 상태코드 00:탁송완료, 10:탁송등록, 11: 기사요청, 20:기사배정, 29:배정취소, 30:탁송시작, 31:경유1도착, 32:경유2도착, 99:요청취소
  stsDt: null,
  stsHistory: [],
  drvElapsed: 0,
  stsElapsed: 0,
  updElapsed: 0,
  opId: '',
  opName: '',
  baseAmt: 0, // 기본요금
  dstAmt: 0, // 거리요금
  farAmt: 0, // 특수지역 출동 요금
  adtAmt: 0,
  waitAmt: 0,
  extAmt: 0,
  prmAmt: 0,
  cnclAmt: 0,
  adjAmt: 0,
  totAmt: 0,
  sumAmt: 0,
  spBaseAmt:  0,
  spTotAmt: 0,

  payCode       : '21', // 결제방식 21:후불-법인청구, 22:후불-계좌입금, 03:현금, 74:카드, 85:마일리지결제, 96:복합결제 (앞1자리=콜마너코드)
  useMilePay    : 0, // 마일리지 요금 - payment: 96 복합결제인 경우 설정
  useCbPay      : 0, // 후불금액 - 96 복합결제인 경우 설정
  useCardPay    : 0, // 카드금액 - 96 복합결제인 경우 설정
  useCashPay    : 0, // 현금금액 - 96 복합결제인 경우 설정

  isAssign: false, // 기사 배정 여부
  isAssCncl: false, // 기사 배청 취소 여부
  isCnclReq: false, // 사용자 예약취소 요청 여부
  isCancled: false, // 취소여부
  cnclDriver: '',// 배차취소사유
  cnclReason: '', // 예약취소사유
  cnclReqDt: null, // 취소요청 일시
  cancelDt: null // 취소일시
};

export const _center = {
  centerCode: '',
  centerName: '',  // 콜센터
  companyName: '', // 고객사명과 다른경우
  prName: '', // 대표자명
  mgrName: '', // (ENC) 담당자명
  taxNo: '', // 사업자번호
  email: '', // (ENC) 전자우편
  arsNo: '', // ARS 번호
  hpNo: '', // 휴대폰번호
  phoneNo: '', // 유선전화번호
  extPhoneNo: '', // 내선번호
  faxNo: '',
  customer: null, // 고객사
  addrZip: '',
  addr: '',
  addrDtl: '',
  addrXy: '',
  openDts: '', // 시작일자 YYYYMMDD
  ioSocket: null
};


export const _drvFee = {
  val : {
    custCode : '',
    code     : '',
    name     : '',
    subCode  : '',
    amt      : 0
  },
  col : {
    custCode : '고객코드',
    code     : '요금코드',
    name     : '요금명',
    subCode  : '하위코드',
    amt      : '금액',
    regId    : '등록자',
    updId    : '수정자',
    createdAt: '등록일시',
    updatedAt: '수정일시',
  }
}

